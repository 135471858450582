<template>
	<!-- <div class="whatsbtn-group" id="wtsbtn"> -->
	<a
		role="button"
		class="whatsbtn-button hvr-grow"
		target="_blank"
		href="https://wa.me/551137402110?text=Olá! eu estou no site alfred.com.br e preciso de atendimento."
		aria-describedby="whatsbtn-button-label"
		style="position: fixed; right: 25px; bottom: 35px; z-index: 99999"
	>
		<i class="fab fa-whatsapp whatsbtn-icon"></i>
		<!-- <div class="whatsbtn-label" id="whatsbtn-button-label">Posso Ajudar?</div> -->
	</a>
	<!-- </div> -->
	<!-- href="https://api.whatsapp.com/send?1=pt_BR&amp;phone=551137402110" -->
</template>

<script>
export default {
	name: 'whatsappButton'
};
</script>

<style type="text/css">
.whatsbtn-group {
	/* right: 60px;
	bottom: 75px;
	display: flex;
	position: fixed;
	transition: ease-in-out 250ms;
	visibility: visible;
	align-items: flex-end;
	flex-direction: column-reverse; */
	z-index: 99999;
	position: fixed;
}

.whatsbtn-button {
	color: #fff;
	width: 56px;
	cursor: pointer;
	height: 56px;
	margin: 0 auto 0 auto;
	display: block;
	outline: none !important;
	position: relative;
	max-width: none !important;
	box-shadow: 0 0px 8px rgba(88, 88, 117, 0.3);
	text-align: center;
	transition: ease-in-out 250ms;
	visibility: visible;
	user-select: none;
	border-radius: 50%;
	margin-bottom: 10px;
	background-size: cover;
	text-decoration: none;
	background-color: rgba(37, 211, 102, 1);
	background-image: none;
	background-repeat: no-repeat;
	background-position: center center;
}
@media screen and (min-width: 770px) {
	.whatsbtn-button {
		right: 0px !important;
		width: 56px;
		height: 56px;
		opacity: 1;
		visibility: visible;
		margin-bottom: 0 !important;
		pointer-events: unset !important;
	}
}

.whatsbtn-icon {
	top: 50%;
	left: 50%;
	color: rgba(255, 255, 255, 1);
	z-index: 1;
	position: absolute;
	font-size: 37px;
	transform: translate(-50%, -50%);
	text-align: center;
	transition: all 0.2s ease-out;
}

.whatsbtn-label {
	top: 50%;
	color: #fff;
	right: 65px;
	margin: 0px 0px 0px 0px;
	padding: 5px 15px 5px 15px;
	z-index: 1;
	position: absolute;
	font-size: 12px;
	transform: translateY(-50%);
	background: rgba(37, 211, 102, 1);
	text-align: end;
	transition: all 250ms ease-in-out;
	line-height: initial;
	white-space: nowrap;
	border-radius: 3px;
	visibility: hidden;
	/*  */
}
@media screen and (min-width: 770px) {
	.whatsbtn-label {
		right: 65px;
		opacity: 1;
		visibility: visible;
	}
}
/* Grow */
.hvr-grow {
	display: inline-block;
	vertical-align: middle;
	transform: translateZ(0);
	/* box-shadow: 0 0 30px rgba(0, 0, 0, 0); */
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	transition-duration: 0.3s;
	transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
	transform: scale(1.1);
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.whatsbtn-group {
		right: 1%;
		bottom: 0px;
		display: flex;
		z-index: 99999;
		position: fixed;
		transition: ease-in-out 250ms;
		visibility: visible;
		align-items: flex-end;
		flex-direction: column-reverse;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
	.whatsbtn-group {
		right: 30px;
		bottom: 75px;
		display: flex;
		z-index: 99999;
		position: fixed;
		transition: ease-in-out 250ms;
		visibility: visible;
		align-items: flex-end;
		flex-direction: column-reverse;
	}
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
	.whatsbtn-group {
		right: 1%;
		bottom: 30px;
		display: flex;
		z-index: 99999;
		position: fixed;
		transition: ease-in-out 250ms;
		visibility: visible;
		align-items: flex-end;
		flex-direction: column-reverse;
	}
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
	.whatsbtn-group {
		right: 3%;
		bottom: 65px;
		display: flex;
		z-index: 99999;
		position: fixed;
		transition: ease-in-out 250ms;
		visibility: visible;
		align-items: flex-end;
		flex-direction: column-reverse;
	}
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
	.whatsbtn-group {
		right: 3%;
		bottom: 90px;
		display: flex;
		z-index: 99999;
		position: fixed;
		transition: ease-in-out 250ms;
		visibility: visible;
		align-items: flex-end;
		flex-direction: column-reverse;
	}
}
</style>
